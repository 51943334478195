import { Button, Card, CardActions, CardContent, CardMedia, Grid, Typography } from "@mui/material";
import { ICube } from "../interfaces";
import Navigation from "./Navigation";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

function Cubes({ cubes }: { cubes: ICube[] }) {
  return (
    <div>
      <Navigation />
      <Grid container spacing={2}>
        {cubes.map((cube: ICube, index: number) => {
          return (
            <Grid item lg={6} xs={12} key={index}>
              <Card sx={{ display: 'flex' }} variant="outlined">
                <div className="cardArt">
                  <CardMedia
                    component="img"
                    sx={{ minHeight: 132, width: 151 }}
                    src={cube.image?.url || 'https://cards.scryfall.io/art_crop/front/3/d/3d946df5-f206-4241-bb55-97db67dc793c.jpg?1562546549'}
                    alt={`Art by ${cube.image?.artist || 'Mark Tedin'}`}
                  />
                  <em>{`Art by ${cube.image?.artist || 'Mark Tedin'}`}</em>
                </div>
                <div className="vertical">
                  <CardContent>
                    <Typography><b>{cube.name.toLocaleUpperCase()}</b> by {cube.owner}</Typography>
                    {cube.description ? (<Typography>{cube.description}</Typography>) : <></>}
                  </CardContent>
                  <CardActions>
                    {cube.url ? (<Button endIcon={<OpenInNewIcon />} target="_blank" rel="noopener noreferrer" href={cube.url}>View on CubeCobra</Button>) : (<></>)}
                  </CardActions>
                </div>
              </Card>
            </Grid>
          )
        })}
      </Grid>
    </div>

  )
}

export default Cubes;
import { Button } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

function Navigation() {
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <div>
        <hr />
        <div className="navigation">
            <Button href="" variant="outlined" disabled={location.pathname === '/'} onClick={() => navigate('/')} className={location.pathname === '/' ? 'active' : 'inactive'}>Events</Button>
            <Button href="" variant="outlined" disabled={location.pathname === '/cubes'} onClick={() => navigate('/cubes')} className={location.pathname === '/cubes' ? 'active' : 'inactive'}>Cubes</Button>
        </div>
        <hr />
    </div>
  )
}

export default Navigation;
import { IEvent } from "../interfaces";
import { createEvent, prettifyDate } from "../services/calendar.service";
import Navigation from "./Navigation";
import { Button, Card, CardActions, CardContent, Grid, Link, Theme, Typography, useMediaQuery } from "@mui/material"
import EventIcon from "@mui/icons-material/Event"
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

function getStoreLink(storeName: string): string {
    switch (storeName) {
        case "Redcap's Corner":
            return "https://www.redcapscorner.com/"
        case "Philly Game Shop":
            return "https://www.phillygameshop.com/"
        default:
            return ""
    }

}

function Events({ events }: { events: IEvent[] }) {
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  return (
    <div>
      <Navigation />
      <Grid container spacing={2}>
      {events.map((event: IEvent, index: number) => {
        const eventSubtitle = (event.owner ? " by " + event.owner : "") + (event.players ? " (" + event.players + " players)": "");
        const altSubtitle = (event.altcube && event.altowner ? " by " + event.altowner : "");
        const storeLink = event.location ? getStoreLink(event.location) : "";
        const eventDate = new Date(event.date)
        return (
          <Grid item lg={6} xs={12} key={index}>
            <Card variant="outlined" className="vertical">
              <CardContent>
                <Typography><b>{event.cube.toLocaleUpperCase()}</b>{eventSubtitle}</Typography>
                {event.altcube ? (<Typography><i>feat. <b>{event.altcube.toLocaleUpperCase()}</b>{altSubtitle}</i></Typography>) : (<></>)}
                <Typography>{prettifyDate(eventDate)}</Typography>
                {event.location ? (<Link target="_blank" rel="noopener noreferrer" href={getStoreLink(event.location)}>{event.location}</Link>) : (<></>)}
                {event.description ? (<Typography>{event.description}</Typography>) : (<></>)}
              </CardContent>
              <CardActions className={isSmallScreen ? 'smallEventActions' : ''}>
                <Button endIcon={<EventIcon />} download="event.ics" href={encodeURI('data:text/calendar;attachment;charset=utf8,' + createEvent('MtG Cube', `${event.cube} ${eventSubtitle}`, event.location || '', storeLink, event.date))}>Save to calendar</Button>
                {event.hyperlink ? (<Button endIcon={<OpenInNewIcon />} target="_blank" rel="noopener noreferrer" href={event.hyperlink}>{`${event.cube} on CubeCobra`}</Button>) : (<></>)}
                {event.altcube && event.althyperlink ? (<Button endIcon={<OpenInNewIcon />} target="_blank" rel="noopener noreferrer" href={event.althyperlink}>{`${event.altcube} on CubeCobra`}</Button>) : (<></>)}
              </CardActions>
            </Card>
          </Grid>
        )
      })}
      </Grid>
    </div>
  )
}

export default Events;